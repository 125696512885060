<template>
    <div class="vertical-horizontal-center has-background-primary">
        <div class="columns is-gapless is-centered is-vcentered is-multiline is-flex">
            <div class="column is-7-mobile is-7-desktop">
                <b-image :src="require('../assets/harpia_logo_alt.png')" ratio="4by2"></b-image>
            </div>
            <section class="section">
                <p class="has-text-weight-bold has-text-white">Cadastrar nova senha</p>
            </section>
            <div class="column is-7-mobile is-7-desktop">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(InsertNewPassword)" class="has-text-left">
                        <b-field>
                            <ValidationProvider rules="required" v-slot="{ errors, untouched, changed, confirmed }" vid="NewPassword">
                                <b-field :type="{ 'is-light': untouched, 'is-light': changed, 'is-light': confirmed, 'is-danger': errors[0] }">
                                    <template slot="label">
                                        <span class="has-text-weight-normal has-text-white-bis is-family-sans-serif">Nova senha</span>
                                    </template>
                                    <b-input v-model="NewPassword" size="is-medium" custom-class="has-background-primary has-text-white-bis" type="password"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </b-field>
                        <b-field>
                            <ValidationProvider rules="required|confirmed:NewPassword" v-slot="{ errors, untouched, changed, confirmed }">
                                <b-field :type="{ 'is-light': untouched, 'is-light': changed, 'is-light': confirmed, 'is-danger': errors[0] }">
                                    <template slot="label">
                                        <span class="has-text-weight-normal has-text-white-bis is-size-6 is-family-sans-serif">Confirmar nova senha</span>
                                    </template>
                                    <b-input v-model="ConfirmNewPassword" size="is-medium" custom-class="has-background-primary has-text-white-bis" type="password"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </b-field>
                        <br>
                        <p class="control has-text-centered">
                            <b-button class="has-text-primary has-custom-width" size="is-medium" native-type="submit" type="is-light" :loading="IsConfirmButtonLoading"><span class="is-family-sans-serif">{{ $t('login.confirm') }}</span></b-button>
                        </p>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.has-custom-width {
    width: 75%
}

.vertical-horizontal-center {
    height: 100vh;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
</style>
<script>
import { ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import { required, confirmed } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
extend('confirmed', confirmed)
extend('required', required)

export default {
    name: 'Reset',
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            IsConfirmButtonLoading: false,
            isComponentModalActive: false,
            Mode: '',
            ActionCode: '',
            NewPassword: '',
            ConfirmNewPassword: ''
        }
    },
    methods: {
        InsertNewPassword() {
            this.IsConfirmButtonLoading = true
            this.$store.dispatch('user/ConfirmPasswordReset', { code: this.ActionCode, password: this.NewPassword })
                .then(() => {
                    this.LaunchToast("Senha alterada com sucesso. Você será redirecionado.", "is-success"),
                    this.IsConfirmButtonLoading = false
                    window.location.hash = ''
                    window.location.reload()
                }).catch(error => {
                    this.LaunchToast(error, "is-danger")
                })
        },
        LaunchToast(message, type) {
            this.$buefy.toast.open({ //Displays toast with given properties below.
                message: message, //Message to display.
                type: type, //Toast's color.
                position: 'is-bottom', //Toast's position.
                duration: 3000 //Toast's duration.
            })
        }
    },
    created() {
        this.Mode = this.$route.query.Mode
        this.ActionCode = this.$route.query.oobCode[1]
    }
}
</script>